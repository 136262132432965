var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container terms" }, [
      _c("div", { attrs: { id: "article" } }, [
        _c("strong", { staticClass: "tit_article" }, [
          _vm._v("서비스 이용약관")
        ]),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [_vm._v("제1조 (목적)")]),
        _c("br"),
        _vm._v(
          "본 회원약관은 ㈜윔즈(이하 '회사'라 한다)가 운영하는 인터넷관련 서비스 ‘헤이비글’(이하’서비스’라 한다)을 이용함에 있어 관리자와 이용자(이하 '회원'이라 한다)의 권리, 의무 및 책임사항의 규정을 목적으로 합니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [_vm._v("제2조 (약관의 효력)")]),
        _c("br"),
        _vm._v(
          "1. 본 약관은 '회사'가 운영하는 ‘서비스’에 회원 가입 시 회원들에게 통지함으로써 효력이 발생됩니다. "
        ),
        _c("br"),
        _vm._v(
          "2. '회사'는 본 약관의 내용을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지 또는 통지함으로써 효력이 발생됩니다. "
        ),
        _c("br"),
        _vm._v(
          "3. 약관의 변경사항 및 내용은 '회사'의 ‘서비스’의 공지사항에 게시하는 방법으로 공시합니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [
          _vm._v("제3조 (약관 이외의 준칙)")
        ]),
        _c("br"),
        _vm._v(
          "본 약관에 명시되지 않은 사항이 전기 통신 기본법, 전기통신 사업법, 기타 관련 법령에 규정되어 있는 경우 그 규정에 따릅니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [_vm._v("제4조 (용어의 정의)")]),
        _c("br"),
        _vm._v("이 약관에서 사용하는 용어의 정의는 다음과 같다. "),
        _c("br"),
        _c("br"),
        _vm._v(
          "1. 서비스 : ‘회사’가 컴퓨터 등 정보통신설비를 이용하여 서비스를 제공할 수 있도록 설정한 가상의 영업장을 말하며, 일반회원과 전문가 회원 상호간 거래가 이루어질 수 있도록 연결해주는 온라인 공간 및 안전결제 서비스를 제공하는 중개서비스를 말합니다. "
        ),
        _c("br"),
        _vm._v(
          "2. 회원 : 본 약관에 따라 '회사'와 서비스 이용에 관한 계약을 체결한 자로서 서비스 이용 목적에 따라 ‘일반회원’과 ‘전문가 회원’으로 구분됩니다. "
        ),
        _c("br"),
        _vm._v(
          "3. 일반회원 : 각종 의뢰를 요청하는 개인 또는 단체에 소속된 자로, 의뢰에 필요한 전문가를 섭외하기 위해 서비스를 이용하는 자를 말합니다. "
        ),
        _c("br"),
        _vm._v(
          "4. 전문가 회원 : 일반회원이 요청하는 각종 의뢰에 지원하는 회원으로서, 의뢰에 참여하기 위한 홍보 및 직접 지원 등을 위해 서비스를 이용하는 자를 말합니다. "
        ),
        _c("br"),
        _vm._v(
          "5. 아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 '회사'가 승인하는 문자와 숫자의 조합을 말합니다. "
        ),
        _c("br"),
        _vm._v(
          "6. 비밀번호 : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합을 말합니다. "
        ),
        _c("br"),
        _vm._v(
          "7. 게시물 : 회원이 자신의 목적(요청, 홍보, 섭외 등)을 위해 본 서비스에 등록하는 정보를 말합니다. "
        ),
        _c("br"),
        _vm._v(
          "8. 안전결제서비스: 일반회원과 전문가 사이의 금전 거래를 안전하게 할 수 있도록 하는 에스크로 방식의 서비스를 말합니다. "
        ),
        _c("br"),
        _vm._v(
          "위 가항에서 정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래관행에 의합니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [
          _vm._v("제5조 (이용 계약의 성립)")
        ]),
        _c("br"),
        _vm._v(
          "1. 이용 계약은 서비스를 이용하려는 자의 약관 동의와 이용 신청(가입 신청)에 대한 회사의 승낙으로 성립됩니다. "
        ),
        _c("br"),
        _vm._v(
          "2. 이용 약관은 회원 가입 시 링크로 제공하며 회원가입 또는 동의 버튼을 누르면 본 약관에 동의하여 이 계약을 체결한 것으로 간주합니다. "
        ),
        _c("br"),
        _vm._v(
          "3. 회원가입은 만 14세 이상의 개인 또는 기업(개인사업자 및 법인사업자 등)만 가능합니다. "
        ),
        _c("br"),
        _vm._v(
          "4. 이용 신청 시 실명으로 신청을 해야 하며, 실명이 아니거나 타인의 정보를 도용할 경우 가입이 거절될 수 있습니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [
          _vm._v("제6조 (회원가입의 승낙)")
        ]),
        _c("br"),
        _vm._v(
          "1. 이용 승낙은 이용 신청자가 가입 양식의 필수 항목을 정확하게 기입하고, 약관에 동의한 후 회원가입신청을 하면 회사가 본 신청에 대해 승인함으로써 체결됩니다. "
        ),
        _c("br"),
        _vm._v(
          "2. '회사'는 다음과 같은 사유가 있는 경우 이용신청 승낙을 거부할 수 있습니다. "
        ),
        _c("br"),
        _vm._v(" ①본인 실명이 아니거나 타인의 명의를 사용하여 신청하는 경우 "),
        _c("br"),
        _vm._v(" ②서비스 이용 신청서의 내용을 허위로 기재하는 경우 "),
        _c("br"),
        _vm._v(" ③영구 이용정지 조치를 받은 회원이 재이용신청을 하는 경우 "),
        _c("br"),
        _vm._v(" ④부정 및 불건전한 목적으로 본 서비스를 이용하고자 하는 경우 "),
        _c("br"),
        _vm._v(" ⑤본 서비스에 부정적 영향을 끼치거나 경쟁관계에 있는 경우 "),
        _c("br"),
        _vm._v(
          " ⑥기타 이용신청자의 귀책사유, 부적절한 언행 등으로 이용승낙이 곤란한 경우 "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [
          _vm._v("제7조(회원탈퇴 및 이용 종료)")
        ]),
        _c("br"),
        _vm._v("1. '회원'은 언제든지 이용계약 해지를 할 수 있습니다. "),
        _c("br"),
        _vm._v(
          "2. '회원'은 전화, 온라인 등의 채널을 이용하여 가입해지를 신청할 수 있으며, '회사'는 접수 즉시 회원 탈퇴 처리를 합니다. "
        ),
        _c("br"),
        _vm._v(
          " 단, 결제 및 지불은 되었으나 종료되지 않은 건이 있는 경우 모든 과정이 정상 종료된 것을 확인 후 탈퇴 처리를 합니다. "
        ),
        _c("br"),
        _vm._v(
          "3. 회원 탈퇴 시 서비스 내 모든 이용 정보는 삭제되며, 이로 인해 발생하는 피해의 책임은 회원 본인에게 있습니다. "
        ),
        _c("br"),
        _vm._v(
          "4. '회사'는 다음과 같은 사유가 있는 경우, 이용계약을 해지할 수 있습니다. "
        ),
        _c("br"),
        _vm._v(
          " ①회원 가입 후 제6조에 정한 이용신청 거부 사유가 확인되는 경우 "
        ),
        _c("br"),
        _vm._v(
          " ②회사 및 다른 회원들의 권리나 명예, 신용 등을 침해하는 행위를 한 경우 "
        ),
        _c("br"),
        _vm._v(" ③외부 결제 유도 등 회사가 정한 운영 정책을 위반하는 경우 "),
        _c("br"),
        _vm._v(
          " ④정책위반 등의 사유로 회사가 요청한 자료를 제출하지 않거나 경고 누적(2회)이 된 경우 "
        ),
        _c("br"),
        _vm._v(" ⑤그 외 회사의 명예를 훼손시키거나 업무를 방해하는 행위 "),
        _c("br"),
        _vm._v(
          "5. 회사가 이용계약을 해지하는 경우 회원에게 이를 통지하고, 해지 전에 소명할 기회를 부여합니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [
          _vm._v("제8조 (서비스 제공의 중지)")
        ]),
        _c("br"),
        _vm._v(
          "'회사'는 다음 각 호에 해당하는 경우 서비스의 제공을 중지할 수 있습니다. "
        ),
        _c("br"),
        _c("br"),
        _vm._v("1. 설비의 보수 등으로 인해 부득이한 상황이 발생한 경우. "),
        _c("br"),
        _vm._v(
          "2. 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지하는 경우. "
        ),
        _c("br"),
        _vm._v(
          "3. 기타 '회사'가 서비스를 제공할 수 없는 합당한 사유가 발생한 경우. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [
          _vm._v("제9조 (안전결제 서비스)")
        ]),
        _c("br"),
        _vm._v(
          "1. '회사'는 일반회원과 전문가 사이의 금전 거래를 안전하게 할 수 있도록 대금 수령, 보호 및 보관, 지급의 업무로 이루어진 에스크로 방식의 안전결제 서비스를 제공합니다. "
        ),
        _c("br"),
        _vm._v(
          "2. '회사'는 안전결제 서비스를 통해 받은 대금을 보관 후 '일반회원'의 의뢰, 요청 업무가 종료된 후 '전문가'에게 지급합니다. "
        ),
        _c("br"),
        _vm._v(
          "3. '일반회원'은 결과물에 문제가 있을 경우 종료 3일 이내에 이의제기 및 지급 보류를 요청할 수 있습니다. "
        ),
        _c("br"),
        _vm._v(
          "4. 안전결제 서비스는 '회사'가 제공하는 결제시스템을 통한 결제 시에만 제공되며 그 외 방법으로 결제 시에는 제공되지 않습니다. "
        ),
        _c("br"),
        _vm._v(
          " 또한 그로 인한 문제 발생 시 회사는 어떠한 책임도 지지 않으며 당사자간 협의를 통해 해결하셔야 합니다. "
        ),
        _c("br"),
        _vm._v(
          "5. 대금 지급 이후의 분쟁, 불만사항은 '일반회원'과 '전문가' 당사자간 협의를 통해 해결해야 합니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [
          _vm._v("제10조 ('회사'의 의무)")
        ]),
        _c("br"),
        _vm._v(
          "1. '회사'는 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다합니다. "
        ),
        _c("br"),
        _vm._v(
          "2. '회사'는 항상 회원의 신용정보를 포함한 개인신상정보의 보안에 대하여 관리에 만전을 기함으로서 회원의 정보보안에 최선을 다합니다. "
        ),
        _c("br"),
        _vm._v(
          "3. ’회사’는 회원 간의 거래 안전성과 신뢰성을 도모하기 위해 안전결제 서비스(에스크로 방식)를 제공합니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [_vm._v("제11조 (개인정보보호)")]),
        _c("br"),
        _vm._v(
          "1. '회사'는 이용자의 정보수집 시 서비스의 제공에 필요한 최소한의 정보를 수집합니다. "
        ),
        _c("br"),
        _vm._v(
          "2. 제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 '회사'가 지나 다음의 경우는 예외로 합니다. "
        ),
        _c("br"),
        _vm._v(
          " ① 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우. "
        ),
        _c("br"),
        _vm._v(
          " ② 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우. "
        ),
        _c("br"),
        _vm._v(
          " ③ 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우. "
        ),
        _c("br"),
        _vm._v(" ④ 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우. "),
        _c("br"),
        _vm._v(
          "3. 회원은 언제든지 '회사'가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 '회사'는 이에 대해 지체 없이 처리합니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [_vm._v("제12조 (회원의 의무)")]),
        _c("br"),
        _vm._v(
          "1. 회원은 관계법령, 이 약관의 규정, 이용안내 및 주의사항 등 '회사'가 통지하는 사항을 준수하여야 하며, 기타 '회사'의 업무에 방해되는 행위를 하여서는 안 됩니다. "
        ),
        _c("br"),
        _vm._v(
          "2. 회원은 '회사'의 사전 승낙 없이 서비스를 이용하여 어떠한 영리 행위도 할 수 없습니다. "
        ),
        _c("br"),
        _vm._v(
          "3. 회원은 서비스를 이용하여 얻은 정보를 '회사'의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다. "
        ),
        _c("br"),
        _vm._v(
          "4. 회원은 이용신청서의 기재내용 중 변경된 내용이 있는 경우 서비스를 통하여 그 내용을 '회사'에게 통지하여야 합니다. "
        ),
        _c("br"),
        _vm._v(
          "5. 일반회원과 전문가 회원간의 계약이 이루어질 경우, 양측 회원은 성실히 계약내용을 이행해야 합니다. "
        ),
        _c("br"),
        _vm._v(
          "6. 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안 됩니다. "
        ),
        _c("br"),
        _vm._v(" ① 다른 회원의 아이디(ID)를 부정 사용하는 행위 "),
        _c("br"),
        _vm._v(" ② 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위 "),
        _c("br"),
        _vm._v(" ③ 선량한 풍속, 기타 사회질서를 해하는 행위 "),
        _c("br"),
        _vm._v(" ④ 타인의 명예를 훼손하거나 모욕하는 행위 "),
        _c("br"),
        _vm._v(" ⑤ 타인의 지적재산권 등의 권리를 침해하는 행위 "),
        _c("br"),
        _vm._v(" ⑥ 해킹행위 또는 컴퓨터바이러스의 유포행위 "),
        _c("br"),
        _vm._v(
          " ⑦ 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송 또는 타 사이트를 링크하는 행위 "
        ),
        _c("br"),
        _vm._v(
          " ⑧ 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위 "
        ),
        _c("br"),
        _vm._v(" ⑨ 기타 관계법령에 위배되는 행위 "),
        _c("br"),
        _vm._v(" ⑩ 회사에서 정한 운영 정책을 위반하는 행위 "),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [
          _vm._v("제13조 (게시물 또는 내용물의 삭제)")
        ]),
        _c("br"),
        _vm._v(
          "'회사'는 서비스의 게시물 또는 내용물이 제12조의 규정에 위반되거나 '회사'가 규정하는 소정의 게시기간을 초과하는 경우 사전 통지나 동의 없이 이를 삭제할 수 있습니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [
          _vm._v("제14조 (게시물에 대한 권리·의무)")
        ]),
        _c("br"),
        _vm._v(
          "1. 회사가 작성한 저작물에 대한 저작권 및 기타 지식재산권은 회사에 귀속됩니다. "
        ),
        _c("br"),
        _vm._v(
          "2. 회원이 작성한 게시물에 대한 저작권과 모든 권리 및 책임은 이를 게시한 회원에게 있습니다. "
        ),
        _c("br"),
        _vm._v(
          "2. ‘회사’는 서비스의 마케팅 및 제휴, 위탁판매 등을 위해 '회원'의 게시물을 사용ㆍ복제ㆍ수정ㆍ출판ㆍ배포할 수 있습니다. "
        ),
        _c("br"),
        _vm._v(
          " 단, '회원'의 요청이 있을 경우에는 '회원'의 게시물을 사용하지 않을 수 있습니다. "
        ),
        _c("br"),
        _vm._v("3. '회사’가 이용하는 게시물의 범위는 아래와 같습니다. "),
        _c("br"),
        _vm._v(" ① 일반회원이 등록한 의뢰 정보 "),
        _c("br"),
        _vm._v(" ② 일반회원이 등록한 후기 "),
        _c("br"),
        _vm._v(
          " ③ 전문가가 등록한 프로필 정보(사진, 영상을 포함한 프로필 컨텐츠) "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [_vm._v("제15조 (양도금지)")]),
        _c("br"),
        _vm._v(
          "회원이 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [
          _vm._v("제16조 (면책 및 손해배상)")
        ]),
        _c("br"),
        _vm._v(
          "1. '회사'는 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 갖지 아니하고,회원은 자기의 책임 아래 서비스를 이용하며, "
        ),
        _c("br"),
        _vm._v(
          " 서비스를 이용하여 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사 선택, 기타서비스 이용과 관련하여 어떠한 불이익이 발생 하더라도 이에 대한 모든 책임은 회원에게 있습니다. "
        ),
        _c("br"),
        _vm._v(
          "2.'회사'는 제12조의 규정에 위반하여 회원간 또는 회원과 제3자간에 서비스를 매개로 하여 물품거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않는다. "
        ),
        _c("br"),
        _vm._v(
          "3. ’회사’는 헤이비글에서 제공하는 서비스 외 거래 중 발생하는 거래 조건 협의 및 불이행에 따른 손실에 대해서는 책임을 지지 않는다 "
        ),
        _c("br"),
        _vm._v(
          "4. 회원 아이디(ID)와 비밀번호의 관리 및 이용상의 부주의로 인하여 발생 되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 모두 회원에게 있습니다. "
        ),
        _c("br"),
        _vm._v(
          "5. 회원이 제12조, 기타 이 약관의 규정을 위반함으로 인하여 '회사'가 회원 또는 제3자에 대하여 책임을 부담하게 되고, "
        ),
        _c("br"),
        _vm._v(
          " 이로 인해 '회사'에게 손해가 발생하게 되는 경우, 본 약관을 위반한 회원은 '회사'에게 발생하는 모든 손해를 배상하여야 하며, 동 손해로부터 '회사'을 면책시켜야 합니다. "
        ),
        _c("br"),
        _vm._v(
          "6. ’회사’에서 제공하는 안전결제서비스 외에 회원 간의 직접 거래 및 송금을 시도하는 경우, 이후 발생되는 문제에 대해 회사는 어떠한 책임도 지지 않으며 회원 간 상호협의를 통해 해결하여야 합니다. "
        ),
        _c("br"),
        _vm._v(
          "7. 회원 또는 회원의 피고용인, 대리인, 기타 도급 및 위임 등으로 회원을 대신하여 이용계약을 이행하는 자의 책임 있는 사유로 이 계약의 이행과 관련하여 상대방에 손해가 발생한 경우, 그 회원은 상대방에게 발생한 손해를 배상할 책임이 있습니다. "
        ),
        _c("br"),
        _vm._v(
          "8. 회원이 계약을 위반함으로써 서비스의 대외 이미지 실추 등 유, 무형적 손해가 발생한 경우, 회원은 회사의 손해를 배상하여야 합니다. "
        ),
        _c("br"),
        _vm._v(
          "9. 8항과 관련하여 원활한 손해배상 진행 및 중재를 위해, 필요한 경우 ‘회사’는 상대방의 개인정보를 회원 및 관할 기관에게 제출할 수 있습니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("strong", { staticClass: "tit" }, [_vm._v("제17조 (분쟁의 해결)")]),
        _c("br"),
        _vm._v(
          "1. '회사'와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 해야 합니다. "
        ),
        _c("br"),
        _vm._v(
          "2. 1항의 규정에도 불구하고 분쟁으로 인하여 소송이 제기될 경우 '서울중앙지방법원’을 관할법원으로 합니다. "
        ),
        _c("br"),
        _c("br"),
        _c("br"),
        _vm._v(
          "㈜윔즈 는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다. "
        ),
        _c("br"),
        _vm._v(
          "㈜윔즈는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다. "
        ),
        _c("br"),
        _vm._v("■ 본 방침은2021년02월08일부터 시행됩니다. "),
        _c("br"),
        _c("br"),
        _c("br"),
        _vm._v("개정 이력 "),
        _c("br"),
        _vm._v("2021년02월08일 - 개정 "),
        _c("br"),
        _vm._v("2018년02월20일 - 최초등록 ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }